import api from "@/api";
// import {AccountAbbrev} from "@/api/staff";
import AccountAbbrev from "./AccountAbbrev";
import store from "@/store";
import { BufferMutations } from "@/store/buffer/mutation-types";
import { Buffer } from "@/store/buffer";
import { mapMutations } from "vuex";
import { DataState } from "./data-states";
import date from 'quasar/src/utils/date.js';;

enum bufferSlice {
  START_SLICE = 7,
  NAV_SLICE = 1,
}
const bufferMethods = {
  setLoading: (payload: boolean) => {
    store.commit("Buffer/" + BufferMutations.SET_BUFFER_LOADING, payload);
  },
  setBufferState: (payload: number) => {
    store.commit("Buffer/" + BufferMutations.SET_BUFFER_STATE, payload);
  },
  setBufferErrorState: (payload: boolean, msg: string) => {
    store.commit("Buffer/" + BufferMutations.SET_BUFFER_ERROR_STATE, payload);
    store.commit("Buffer/" + BufferMutations.SET_BUFFER_ERROR_STATE, msg);
  },
  addIdsToLoadedMessages: (obj: any) => {
    store.commit("Buffer/ADD_TO_LOADING_IDS", obj);
  },
  fetchSlice: () => {
    const messages = store.getters["Message/allMessages"];
    if (messages) {
      const currIdx = store.getters["Buffer/getCurrIdx"];
      const endIdx = store.getters["Buffer/getEndIdx"];
      const messagesToLoad = messages.slice(currIdx, endIdx);
      messagesToLoad.forEach((element: any) => {
        bufferMethods.addIdsToLoadedMessages(element);
      });
    }
  },
  openPage(
    accountAbbrev: AccountAbbrev,
    type: any,
    id: string,
    typeId?: string,
  ) {
    return new Promise((resolve, reject) => {
      api.Staff.getPage(accountAbbrev, type, id, typeId)
        .then((res: any) => {
          const pageObject: any = {};
          pageObject[id] = res.data;
          store.commit("Buffer/REMOVE_FROM_LOADING_IDS", id);
          if (res.is_success == false) {
            console.log("Have a is_success error.");
            reject(res.is_success);
          }
          resolve(pageObject);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadPageDetails(obj: any) {
    if (obj.hasOrder) {
      const _type = "order";
      return bufferMethods.openPage(
        obj.accountAbbrev,
        _type,
        obj.id,
        obj.orderId,
      );
    }
    return bufferMethods.openPage(obj.accountAbbrev, "message", obj.id);
  },
  loadIds: async (id: string) => {
    // console.log("ID DUBUG " + id);
    bufferMethods.setLoading(false);
    bufferMethods.setBufferState(DataState.LOADING);
    const idsToLoad = store.getters["Message/getIdsToLoad"](id);
    if (idsToLoad === -1) {
      bufferMethods.setLoading(false);
      bufferMethods.setBufferState(DataState.ERROR);
      return;
    }
    for (const message of idsToLoad) {
      bufferMethods.loadMessage(message).catch((error) => {
        console.log(error);
        console.log("IAFJOSFOSFNASOFIOSNFOSIFOSIN ");
      });
    }
  },
  loadMessage: async (message: any) => {
    const id = message.id;
    const isInBuffer = bufferMethods.checkBuffer(id);
    const isInLoadingIds = bufferMethods.checkLoadingIds(id);
    if (isInBuffer) {
      return;
    } else if (isInLoadingIds) {
      return;
    }
    bufferMethods.addIdsToLoadedMessages(message);
    bufferMethods
      .loadPageDetails(message)
      .then((res) => {
        store.commit("Buffer/SET_BUFFER", res);
        bufferMethods.setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          const code = err.response.status;
          if (code === 500) {
            bufferMethods.setBufferErrorState(true, "Server Error: Code: 500");
          }
        }
        return err;
      });
  },
  checkBuffer: (id: string) => {
    const isInBuffer = store.getters["Buffer/isInBuffer"](id);
    return isInBuffer;
  },
  checkLoadingIds: (id: string) => {
    const loading = store.getters["Buffer/isInLoadingIds"](id);
    return loading;
  },
  loadPageFromBuffer: (id: string) => {
    const pageDetails = store.getters["Buffer/getPageDetails"](id);
    return pageDetails;
  },
  addToBuffer: () => {
    //empty
  },
  navigateBuffer: () => {
    //Empty
  },
};

export { bufferMethods };
