
import Vue from "vue";
import date from 'quasar/src/utils/date.js';;
import copyToClipboard from 'quasar/src/utils/copy-to-clipboard.js';;
import { mapActions, mapMutations } from "vuex";
import { mapGetters } from "vuex";
import SuggestedReplies from "@/components/SuggestedReplies.vue";
import SendToAdminButton from "@/components/SendToAdminButton.vue";
import PageError from "@/components/PageError.vue";
import Api from "@/api";
import { ActionTypes } from "@/store/messages/action-types";
import { MessageMutations } from "@/store/messages/mutation-types";
import staff from "@/api/staff";
import ButtonDeleteMessage, {
  ButtonType,
} from "@/components/ButtonDeleteMessage.vue";
import { MessageObj, OrderPageDetails } from "@/models";
import { DataState } from "@/services/data-states";
import ResponseError from "@/services/response-error";
import { Info } from "@/services/notif-utils";
import { PageState } from "@/services/page-state.interface";
import { bufferMethods } from "@/services/message-buffer-helper";

export default Vue.extend({
  components: {
    SuggestedReplies,
    //  ButtonDeleteMessage,
    SendToAdminButton,
    PageError,
  },
  name: "MessageDetail",
  data() {
    return {
      currentAccountAbbrev: "",
      pageState: PageState.LOADING,
      failMsg: "",
      errorMessage: "",
      pageError: false,
      mapLoading: false,
      map: { asin: null, auctionId: null },
      lines: 1,
      loading: true,
      sendBtnLoading: false,
      loadedFirstBgMessage: false,
      data: {},
      editor: "",
      subject:
        "Question regarding  your item 'Celin Dion' - for Sale on Discogs",
      timestamp: "23-May-3091 3:40 a.m",
      replyMessage: true,
    };
  },
  computed: {
    ...mapGetters({
      nextMessage: "Message/nextMessage",
      getBufferStep: "Message/getBufferStep",
      getBufferLoadedState: "Message/getBufferLoadedState",
      // getBuffer: "Message/getBuffer",
      bufferState: "Message/getBufferState",
      // getNextTwo: "Message/getNextTwo",
      bufferLengh: "Message/getBufferLength",
      isNextPage: "Message/isNextPage",
    }),
    detailsLoading(): any {
      if (this.pageError) {
        return false;
      }
      if (this.pageDetails) {
        return false;
      }
      return true;
    },
    pageDetails(): any {
      return this.$store.getters["Buffer/getPageDetails"](this.currentRouteID);
    },
    currentRouteID(): string {
      const routeID: string = this.$route.params.messageId;
      return routeID;
    },
    nextRouteID(): string {
      const nextID = this.$store.getters["Message/getNextID"](
        this.currentRouteID
      );
      if (nextID === -1) {
        return "";
      }
      return nextID;
    },
    prevRouteID(): string {
      const prevID = this.$store.getters["Message/getPrevID"](
        this.currentRouteID
      );

      if (prevID === -1) {
        return "";
      }
      return prevID;
    },
    getBuffer(): OrderPageDetails[] {
      return this.$store.getters["Message/getBuffer"];
    },
    getNextTwo(): MessageObj[] {
      return this.$store.getters["Message/getNextTwo"](
        this.$route.params.messageId
      );
    },
    bufferEnd(): number {
      const length = this.bufferLengh;
      const end = length - 1;
      return end;
    },
    mapCheck(): string {
      const asin: any = this.map.asin;
      const auctionId: any = this.map.auctionId;
      if (asin != null) {
        return "asin";
      } else if (auctionId != null) {
        return "yahoo";
      } else {
        return "";
      }
    },
    isManager(): boolean {
      return this.$store.getters["Auth/isManager"];
    },
    pageButton(): ButtonType {
      return {
        bulk: false,
        table: false,
        page: true,
      };
    },
    messageId(): string {
      return this.$route.params.messageId;
    },
  },
  watch: {
    pageDetails() {
      this.renderPage();
    },
  },
  methods: {
    ...mapActions({
      sendToAdminAction: "Message/" + ActionTypes.SEND_TO_ADMIN,
      markAsReadAction: "Message/" + ActionTypes.MARK_AS_READ,
    }),
    ...mapMutations({
      setBuffer: "Message/" + MessageMutations.SET_BUFFER,
      setBufferLoaded: "Message/" + MessageMutations.SET_BUFFER_LOADED,
      setBufferStep: "Message/" + MessageMutations.SET_BUFFER_STEP,
      addBufferStep: "Message/" + MessageMutations.ADD_BUFFER_STEP,
      setBufferLoading: "Message/" + MessageMutations.SET_BUFFER_LOADING,
      setBufferState: "Message/" + MessageMutations.SET_BUFFER_STATE,
    }),
    formatCondition(condition: string) {
      const re = /^.*\(|\)/g;
      const formattedString = condition.replace(re, " ");
      console.log(formattedString);
      return formattedString;
    },
    nextPage() {
      console.log("This asdfasdfa sdfjljfsdf");
      console.log(this.messageId);
      const nextItem = this.nextMessage(this.messageId);
      if (nextItem < 0) {
        this.$q.notify({
          position: "top",
          message: "End of list",
          icon: "tapas",
        });
        return;
      }
      if (nextItem.hasOrder) {
        this.$router.push({
          name: "OrderDetail",
          params: {
            messageId: nextItem.id,
            orderId: nextItem.orderId,
          },
        });
      } else {
        this.$router.push({
          name: "MessageDetail",
          params: { messageId: nextItem.id },
        });
        this.$q.notify({
          position: "top-left",
          message: "Fetching new message page",
        });
      }
    },
    openLink() {
      if (this.mapCheck === "asin") {
        const asin: any = this.map.asin;
        const url = `https://www.amazon.co.jp/dp/${asin}?_encoding=UTF8&aod=1`;
        window.open(url, "_blank")?.focus();
      } else if (this.mapCheck === "yahoo") {
        const auctionId: any = this.map.auctionId;
        const url = `https://page.auctions.yahoo.co.jp/jp/auction/${auctionId}`;
        window.open(url, "_blank")?.focus();
      } else return;
    },
    copyId(param: any) {
      copyToClipboard(param)
        .then(() => {
          this.$q.notify({
            type: "dark",
            position: "top",
            message: "Copied to clip board",
            icon: "content_copy",
          });
        })
        .catch(() => {
          this.$q.notify({
            position: "top",
            message: "Failed to copy",
          });
        });
    },
    reply() {
      this.replyMessage = true;
    },
    cancelReply() {
      this.replyMessage = false;
    },
    getImage(imageList: any) {
      return imageList[0];
    },
    expand() {
      if (this.lines == 1) {
        this.lines = 0;
      } else {
        this.lines = 1;
      }
    },
    sendToAdmin(id: any) {
      this.sendToAdminAction(id)
        .then(() => {
          this.$q.notify({
            position: "top",
            type: "positive",
            message: "Sent to admin Successfully",
          });
        })
        .catch((error) => {
          this.$q.notify({
            position: "top",
            type: "warning",
            message: "Error on send :(",
          });
        });
    },
    checkMapping(pageData: any) {
      this.mapLoading = true;
      const itemID = pageData.item_id;
      if (itemID == null) {
        Info("No item id found for this item");
        return;
      }
      Api.Staff.getMapForActiveListing(itemID)
        .then((response: any) => {
          this.map.asin = response.asin;
          this.map.auctionId = response.auctionID;
          this.mapLoading = false;
        })
        .catch((err) => {
          this.$q.notify({
            position: "top-right",
            type: "warning",
            message: err,
          });
        });
    },

    async renderPage() {
      console.log("Getting Page");
      this.loading = true;
      this.pageState = PageState.LOADING;
      this.data = this.pageDetails;
      this.currentAccountAbbrev = this.pageDetails["account_abbrev"];
      if (this.data == undefined) {
        this.pageState = PageState.INIT;
        this.loading = false;
        return;
      }
      this.pageState = PageState.SUCCESS;
      await this.checkMapping(this.data);
      this.loading = false;
      return;
    },
  },
  created() {
    const pageCheck = this.isNextPage;
    this.pageState = PageState.LOADING;
    this.markAsReadAction(this.$route.params.messageId);
    console.log(this.currentRouteID);
    bufferMethods.loadIds(this.currentRouteID).catch((err) => {
      console.log(err);
      this.pageState = PageState.FAIL;
      this.loading = false;
    });
    this.renderPage();
    // this.checkMapping();
  },
});
