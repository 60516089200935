import { KeyboardShortcuts } from "@/models";
const keyboardHelper = (
  keyboardShortcuts: KeyboardShortcuts[],
  destroy: boolean
) => {
  const keyPress = (event: KeyboardEvent) => {
    if (event.ctrlKey) {
      const keyPressed = keyboardShortcuts.find(({ key }) => {
        return key === event.key;
      });
      console.log(keyPressed);
      if (keyPressed) {
        event.preventDefault();
        keyPressed.fn();
      } else {
        return;
      }
    }
  };
  window.addEventListener("keydown", keyPress);
  if (destroy) {
    window.removeEventListener("keydown", keyPress);
  }
};
export default keyboardHelper;
