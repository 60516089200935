import { TreeRow } from "@/models/models";
import { MainNode } from "@/models/models";
import { Info, Warn } from "./notif-utils";
import message_templates_api from "@/api/message_templates";
import store from "@/store";
declare global {
  interface Navigator {
    msSaveBlob: (blob: Blob, fileName: string) => boolean;
  }
}
const hasChildren = (node: MainNode): boolean => {
  if (node.children) {
    return true;
  }
  return false;
};
const hasReply = (node: MainNode): boolean => {
  if (node.reply) {
    return true;
  }
  return false;
};
const createList = (): Array<TreeRow> => {
  const categories = store.getters["General/tree"] as Array<MainNode>;
  const finalTreeArray = [] as Array<TreeRow>;
  const currentObjectShape = {} as any;
  for (const node of categories) {
    currentObjectShape["mainAncestor"] = node["description"];
    // console.log(node.description);
    // console.log(currentObjectShape);
    generator(finalTreeArray, node, currentObjectShape);
  }
  console.log(finalTreeArray);
  return finalTreeArray;
};

const generator = (
  finalList = [] as Array<TreeRow>,
  node: MainNode,
  currentShape = {} as TreeRow,
  ancestors = [] as Array<string>
) => {
  if (hasChildren(node)) {
    currentShape.ancestors = ancestors;
    currentShape["ancestors"].push(node["description"]);
    const ancestorsCurrent = currentShape["ancestors"];
    for (const child of node["children"]) {
      generator(finalList, child, currentShape, ancestorsCurrent);
    }
  }
  if (hasReply(node)) {
    const COS = currentShape;
    const reply = store.getters["General/getNewReply"](node["reply"]);
    COS["parent"] = node["description"];
    COS["reply"] = reply;
    // COS["ancestors"].push();
    const length = COS["ancestors"].length;
    const start = length - 5;
    const end = length - 1;
    // COS["ancestors"] = COS["ancestors"].splice(
    //   start,
    //   end
    // );
    // console.log(COS);
    // console.log(COS);
    finalList.push(COS);
    // return finalList;
  }
};

const createCSV = () => {
  return new Promise((resolve, reject) => {
    Info("Fetching Tree From Server");
    message_templates_api
      .fetchTreeForCSVParser()
      .then((responseData: any) => {
        console.log(responseData.data);
        resolve(convertArrayToCSV(responseData.data));
      })
      .catch((error: any) => {
        console.log(error);
        reject(error);
        Warn("Unable to Fetch Tree");
      });
  });
};
const convertArrayToCSV = (data: [any]) => {
  Info("Parsing Tree to CSV");
  const csv = [];
  const headerRow = Object.keys(data[0]);
  csv.push(headerRow.join(","));

  data.forEach((el) => {
    const row = headerRow.map((key) => {
      let value = el[key];
      if (typeof value === "string") {
        value = value.replace(/"/g, '""');
        if (value.indexOf(",") >= 0 || value.indexOf("\n") >= 0) {
          value = '"' + value + '"';
        }
        return value;
      }
    });
    csv.push(row.join(","));
  });
  const csvString = csv.join("\n");
  const blob = new Blob([csvString], { type: "text/csv;charset=utf8, " });
  const objectUrl = URL.createObjectURL(blob);
  downloadToClient(blob, objectUrl);
};
const downloadToClient = (blobFile: any, fileUrl: any) => {
  Info("Downloading to Local");
  const filename = "templates.csv";
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blobFile, filename);
  } else {
    const url = fileUrl;
    const link = document.createElement("a");
    if (link.download !== undefined) {
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export default { createList, generator, createCSV };
