import { render, staticRenderFns } from "./SuggestedReplies.vue?vue&type=template&id=4d826204"
import script from "./SuggestedReplies.vue?vue&type=script&lang=js"
export * from "./SuggestedReplies.vue?vue&type=script&lang=js"
import style0 from "./SuggestedReplies.vue?vue&type=style&index=0&id=4d826204&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QInput from 'quasar/src/components/input/QInput.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpinnerDots from 'quasar/src/components/spinner/QSpinnerDots.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinnerGears from 'quasar/src/components/spinner/QSpinnerGears.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QTree from 'quasar/src/components/tree/QTree.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QInput,QChip,QBtn,QSpinnerDots,QCard,QCardSection,QSpace,QSeparator,QInnerLoading,QSpinnerGears,QIcon,QScrollArea,QTree});
