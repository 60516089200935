
import Vue from "vue";
import DateUtils from "@/services/date-utils";
export default Vue.extend({
  name: "Timeline",
  props: { thread: [] },
  data() {
    return {
      timelineIcon(imgUrl: any) {
        if (imgUrl) {
          return imgUrl;
        }
      }
    };
  },
  methods: {
    timeAgoFormat(date: string) {
      const dateF = DateUtils.getDaysAndHours(date);
      return dateF;
    }
  }
});
