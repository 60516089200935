import {
  format,
  formatDistanceToNow,
  parse,
  differenceInHours,
  differenceInDays
} from "date-fns";

const dateFormats = {
  default: "d MMM yyyy, h:mm aa"
};

const parseDate = date => {
  const newDateString = parse(date, "dd-MMM-yy hh:mm aa", new Date());
  const dateObj = new Date(newDateString);
  return dateObj;
};

const getDaysDifference = date => {
  let suffix = "days";
  const today = Date.now();
  const diff = differenceInDays(today, date);
  if (diff == 0) {
    return "";
  }
  if (diff == 1) {
    suffix = "day";
  }
  return `${diff} ${suffix}`;
};
const getHoursDiffernce = date => {
  const today = Date.now();
  let suffix = "hours ago";
  let diff = differenceInHours(today, date, { roundingMethod: "ceil" });

  const dayDiff = differenceInDays(today, date);
  if (dayDiff > 0) {
    const inHours = dayDiff * 24;
    const newDiff = diff - inHours;
    diff = newDiff;
  }
  if (diff == 1) {
    suffix = "hour ago";
  }
  if (diff < 1) {
    suffix = "hour";
    diff = `less than 1`;
  }
  return `${diff} ${suffix}`;
};
const getDaysAndHours = date => {
  const dateString = parseDate(date);
  const days = getDaysDifference(dateString);
  const hours = getHoursDiffernce(dateString);
  const finalDate = days + " " + hours;
  return finalDate;
};

const getTimeAgo = date => {
  const parsedDate = parseDate(date);
  const timeAgo = formatDistanceToNow(parsedDate, { addSuffix: true });
  return timeAgo;
};

const formatDate = (date, format_ = dateFormats.default) => {
  const parsedDate = parseDate(date);
  const formattedDate = format(parsedDate, format_);
  return formattedDate;
};

export default { getTimeAgo, formatDate, getDaysAndHours };
