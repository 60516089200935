<script>
import Vue from "vue";
import Api from "@/api";
import HTTP from "@/services/HttpStatusCodes";
import AccountAbbrev from "@/services/AccountAbbrev";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import { mapMutations } from "vuex";
import { MessageMutations } from "@/store/messages/mutation-types";
import actionTypes, { ActionTypes } from "@/store/messages/action-types";
import Timeline from "@/components/Timeline.vue";
import Message from "@/components/Message.vue";
import QuickMessageTemplate from "@/components/QuickMessageTemplate.vue";
import ButtonDeleteMessage from "@/components/ButtonDeleteMessage.vue";
import PageNavigation from "@/components/PageNavigation.vue";
import KeyboardShortcutsMixin from "@/mixins/KeyboardShortcutsMixin.js";
import { Info, Success, Warn } from "@/services/notif-utils";
import { DataState } from "@/services/data-states";
import SSA from "@/services/session-helper";
import TTP from "@/services/template-tree-parser";
import { SessionActionNames as SSC } from "@/models/SessionStatsConstants";

export default Vue.extend({
  name: "SuggestedReplies",
  components: {
    Timeline,
    Message,
    QuickMessageTemplate,
    ButtonDeleteMessage,
    PageNavigation,
  },
  props: {
    shippingService: String,
    isMessagePage: Boolean,
    trackingCode: String,
    data: {},
    id: String,
    nextID: String,
    prevID: String,
    orderId: String,
    replyTo: String,
    message: String,
    htmlMessage: String,
    thread: Array[{}],
    fromUsername: String,
    thumbnail: String,
    currentAccountAbbrev: AccountAbbrev,
    messageId: String,
  },
  mixins: [KeyboardShortcutsMixin],

  data() {
    return {
      fetchingResponse: false,
      keymap: [
        {
          key: "d",
          fn: this.sendWithDelete,
        },
        {
          key: "s",
          fn: this.send,
        },
        {
          key: "f",
          fn: this.focusSearch,
        },
        {
          key: "m",
          fn: this.focusEditor,
        },
      ],
      loading: false,
      replyUUID: null,
      selectedNode: null,
      editorFocus: false,
      searchTerm: "",
      saving: false,
      polling: null,
      messageToSave: "",
      loadedTemplate: "",
      editor: "",
      replies: [],
      expandedKeys: [],
      chosenReply: null,
      thumbStyle: {
        right: "4px",
        borderRadius: "5px",
        backgroundColor: "#027be3",
        width: "5px",
        opacity: 0.75,
      },
      barStyle: {
        right: "2px",
        borderRadius: "9px",
        backgroundColor: "#027be3",
        width: "9px",
        opacity: 0.2,
      },
      csvLoader: false,
    };
  },
  watch: {
    searchTerm() {
      if (this.searchTerm != "") {
        this.$refs.tree.expandAll();
        return;
      }
      this.$refs.tree.collapseAll();
      return;
    },
  },
  computed: {
    ...mapGetters({
      nextMessage: "Message/nextMessage",
      bufferLoading: "Message/getBufferLoading",
      bufferState: "Message/getBufferState",
      bufferLoaded: "Message/getBufferLoadedState",
    }),
    orderMessageType() {
      if (this.isMessagePage) {
        return "message";
      }
      return "order";
    },
    replyWithTemplate() {
      if (this.editor === this.loadedTemplate) {
        return true;
      }
      return false;
    },
    pageButton() {
      return {
        bulk: false,
        table: false,
        page: true,
      };
    },
    suggestedReplies() {
      const tree = this.$store.state.General.tree;
      if (tree !== null && tree.length > 0) {
        return this.newTree(tree);
      }
      return [];
    },
    // messageId() {
    //   const idFromParams =
    //   return this.$route.params.messageId;
    // },
    shouldExpand() {
      if (this.searchTerm != "") {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapMutations({
      deleteMessageMutation: "Message/" + MessageMutations.DELETE_MESSAGE,
      setIsNextPage: "Message/" + MessageMutations.SET_IS_NEXT_PAGE,
    }),

    focusSearch() {
      this.$refs.search.focus();
    },
    focusEditor() {
      this.$refs.editor.focus();
    },
    clearEditor() {
      this.editor = "";
    },
    checkForChange() {
      const message = this.editor;
      const messageChange = this.messageToSave;
      if (message === messageChange) {
        return false;
      } else {
        return true;
      }
    },
    getMessageFromCache() {
      const mId = localStorage.getItem("messageId");
      if (mId == this.messageId) {
        const msg = localStorage.getItem("messageBody");
        this.editor = msg;
        return;
      } else {
        this.editor = "";
      }
    },
    saveMessageToLocalLogic() {
      this.saving = true;
      const checkChange = this.checkForChange();
      if (checkChange) {
        this.messageToSave = this.editor;
        localStorage.setItem("messageBody", this.messageToSave);
        localStorage.setItem("messageId", this.messageId);
        setTimeout(() => {
          this.saving = false;
        }, 1000);
      } else {
        this.saving = false;
      }
    },
    saveMessageToLocal() {
      const interval = 3 * 1000;
      this.polling = setInterval(() => {
        this.saveMessageToLocalLogic();
      }, interval);
      // this.saving = false
    },
    stopSaving() {
      clearInterval(this.polling);
    },
    sendWithDelete() {
      this.send(true, this.messageId);
    },
    send(shouldDelete = false, id) {
      const message = this.editor;
      const replyTemplate = this.chosenReply;
      let orderId = this.orderId;
      if (orderId === undefined) orderId = null;
      if (message != "") {
        this.$store.dispatch("Message/" + ActionTypes.LOAD_OUTBOUND_QUEUE);
        // else {
        Api.Staff.sendMessage(
          this.orderMessageType,
          this.currentAccountAbbrev,
          this.messageId,
          orderId,
          message,
          replyTemplate,
          this.replyUUID,
          shouldDelete
        )
          .then(() => {
            if (shouldDelete == false) {
              Success("Message Sent!");
              if (this.replyWithTemplate) {
                SSA.setStatAction(SSC.TEMPLATE_REPLY).catch(() => {
                  Warn("Unable to set stat");
                });
              }
              SSA.setStatAction(SSC.REPLY).catch(() => {
                Warn("Unable To Set Stat");
              });
            }
            localStorage.removeItem("messageId");
            localStorage.removeItem("messageBody");
            if (shouldDelete === true && id) {
              SSA.setStatAction(SSC.SEND_DELETE).catch(() => {
                Warn("Unable to set Stat");
              });
              if (this.replyWithTemplate) {
                SSA.setStatAction(SSC.TEMPLATE_REPLY).catch(() => {
                  Warn("Unable to set Stat");
                });
              }
              SSA.setStatAction(SSC.REPLY).catch(() => {
                Warn("Unable to set Stat");
              });
              this.sendDelete(id);
            }
          })
          .catch((err) => {
            if (err.response) {
              const errorCode = err.response.status;
              switch (errorCode) {
                case HTTP.BAD_REQUEST:
                  this.$q.notify({
                    position: "top-right",
                    type: "negative",
                    message: "Message is already due for processing",
                  });
                  break;
                case HTTP.SERVER_ERROR:
                  this.$q.notify({
                    position: "top-right",
                    type: "negative",
                    message: "Server Error :(",
                  });
                  break;
                case HTTP.UNAUTHORIZED:
                  this.$q.notify({
                    position: "top-right",
                    type: "warning",
                    message: "Session Timeout",
                  });
                  break;
              }
            }
          });
        // }
      } else {
        this.$q.notify({
          position: "top-right",
          type: "warning",
          message: "No Message in editor",
        });
      }
    },
    nextPage(nextItem) {
      // const nextItem = this.nextMessage(this.messageId);
      this.setIsNextPage(true);
      if (nextItem < 0) {
        Info("End of list");
        this.$router.push({
          name: "Unread",
        });
        // this.$q.notify({
        //   position: "top",
        //   message: "End of list",
        //   icon: "tapas",
        // });
        return;
      }
      if (nextItem.hasOrder) {
        this.$router.push({
          name: "OrderDetail",
          params: {
            messageId: nextItem.id,
            orderId: nextItem.orderId,
          },
        });
      } else {
        this.$router.push({
          name: "MessageDetail",
          params: { messageId: nextItem.id },
        });
        this.$q.notify({
          position: "top-left",
          message: "Fetching new message page",
        });
      }
    },
    sendDelete(id) {
      const itemObj = this.nextMessage(id);
      this.setIsNextPage(true);
      if (itemObj == -1) {
        console.log("Item Object " + itemObj);
        Info("No Next ID, Found, heading back to Inbox.. :)");
        this.deleteMessageMutation(id);
        Success("Message Sent & Deleted!");
        return this.$router.push({
          name: "Unread",
        });
      }
      if (itemObj === undefined) {
        console.log("Item Object " + itemObj);
        Info("Next Message/Order Object not available...");
        return;
      }
      console.log(itemObj);
      this.deleteMessageMutation(id);
      if (itemObj.hasOrder) {
        this.$router.push({
          name: "OrderDetail",
          params: {
            messageId: itemObj.id,
            orderId: itemObj.orderId,
          },
        });
        this.$q.notify({
          position: "top-left",
          message: "Fetching new message page",
        });
      } else {
        this.$router.push({
          name: "MessageDetail",
          params: { messageId: itemObj.id },
        });
        this.$q.notify({
          position: "top-left",
          message: "Fetching new message page",
        });
      }
    },
    placeTemplate(payload) {
      this.editor = payload.messageBody;
    },
    setChosen(key) {
      this.focusEditor();
      this.chosenReply = key;
      this.fetchReply(true);
    },
    fetchReply(isQuickTemplate = false) {
      this.editor = "";
      this.fetchingResponse = true;
      if (this.chosenReply == null) {
        this.fetchingResponse = false;
        return;
      }
      let type = Api.Staff.ItemType.Order;
      if (this.isMessagePage) {
        type = Api.Staff.ItemType.Message;
      }
      // console.log(this.data);
      // console.log(this.data.account_abbrev);
      Api.Staff.getSubstitutionInReply(
        type,
        this.chosenReply,
        this.messageId,
        this.orderId,
        isQuickTemplate,
        this.currentAccountAbbrev
      )
        .then((data) => {
          console.log(data);
          this.editor = data.message;
          this.loadedTemplate = data.message;
          this.fetchingResponse = false;
        })
        .catch((error) => {
          this.fetchingResponse = false;
          this.$q.notify({
            position: "top",
            type: "warning",
            message: "Error fetching response",
          });
        });
    },
    updateSelected(key) {
      console.log(key);
      const node = this.$refs.tree.getNodeByKey(key);
      if (node.reply) {
        this.chosenReply = node.reply;
        this.fetchReply();
      } else {
        this.chosenReply = null;
      }
    },
    newTree(tree) {
      if (this.searchTerm.length == 0 && this.replies.length == 0) {
        this.expandedKeys.splice(0);
        return tree;
      }

      const finalTree = [];
      for (const item of tree) {
        if (this.searchTerm.length > 0) {
          if (
            item.description
              .toLowerCase()
              .indexOf(this.searchTerm.toLowerCase()) > -1
          ) {
            this.expandedKeys.push(item.index);
            finalTree.push(item);
            continue;
          }
        }

        if (
          this.replies.length > 0 &&
          item.reply !== null &&
          this.replies.indexOf(item.reply) > -1
        ) {
          this.expandedKeys.push(item.index);
          finalTree.push(item);
          continue;
        }

        // get only relevant children
        if (item.children) {
          const matchingChildren = this.newTree(item.children);
          const newItem = Object.assign({}, item, {
            children: matchingChildren,
          });
          if (matchingChildren.length > 0) {
            this.expandedKeys.push(item.index);
            finalTree.push(newItem);
          }
        }
      }

      return finalTree;
    },
    async generateCSV() {
      this.csvLoader = true;
      TTP.createCSV()
        .then(() => {
          this.csvLoader = false;
        })
        .catch((err) => {
          this.csvLoader = false;
          console.log(err);
        });
      // this.csvLoader = false;
    },
  },
  created() {
    this.getMessageFromCache();
    // const interval = 3 * 1000 // 3 second interval
    // setInterval(() => {
    //   this.saveMessageToLocal()
    // }, interval);
    Api.getUUID().then((uuid) => {
      this.replyUUID = uuid;
    });
    let type = "order";
    if (this.isMessagePage) {
      type = "message";
    }

    // this.loading = true;
    /* DEPRACETED*/
    // Api.Staff.getSuggestionsForMessage(
    // this.currentAccountAbbrev,
    // type,
    // this.messageId,
    // this.orderId
    // )
    // .then((data) => {
    // this.loading = false;
    // this.replies = data.replies;
    // })
    // .catch((err) => {
    // this.loading = false;
    // });
  },
  onBeforeDestroy() {
    this.stopSaving();
  },
});
</script>
<template>
  <div class="row q-gutter-xl q-col-gutter-md q-col-gutter-xs">
    <div class="col-md-6 col-lg-6">
      <template v-if="isMessagePage">
        <div class="q-mb-xs">
          <q-input
            v-if="isMessagePage"
            outlined
            dense
            v-model="data.title"
            type="text"
            prefix="RE:"
          />
        </div>
      </template>
      <div class="q-pa-xs" style="min-width: 480px">
        <q-chip
          class="glossy"
          color="primary"
          dense
          size="lg"
          text-color="white"
          >{{ currentAccountAbbrev }}</q-chip
        >
        <q-input
          :loading="fetchingResponse"
          v-model="editor"
          filled
          type="textarea"
          counter
          clearable
          placeholder="(CTRL + m to focus)"
          ref="editor"
          @focus="saveMessageToLocal"
          @blur="stopSaving"
        >
        </q-input>
      </div>

      <div class="q-mt-md q-mb-md">
        <q-btn
          class="q-mr-md"
          color="green"
          outline
          icon="cancel_schedule_send"
          label="Send & Delete (d)"
          @click="send(true, messageId)"
        />
        <q-btn
          color="green"
          icon="send"
          label="Send (s)"
          @click="send(false)"
        />
        <button-delete-message
          :type="pageButton"
          :messageIds="[messageId]"
          :currentAccountAbbrev="currentAccountAbbrev"
          @nextPage="nextPage"
          class="q-ml-md"
        />
        <template v-if="saving">
          <q-spinner-dots class="q-ml-md" size="2em" color="secondary" />
        </template>
        <template v-else>
          <q-btn
            class="q-ml-md"
            size="sm"
            unelevated
            color="grey"
            rounded
            icon="check"
            label="saved"
          ></q-btn>
        </template>
        <Page-navigation
          :messageId="messageId"
          :nextID="nextID"
          :prevID="prevID"
        />
      </div>
      <template v-if="!isMessagePage">
        <timeline :thread="thread" />
      </template>
      <template v-else>
        <Message
          :message="message"
          :htmlMessage="htmlMessage"
          :id="data.item_id"
          :nextID="nextID"
          :title="data.title"
          :fromUsername="fromUsername"
          :thumbnail="thumbnail"
        />
      </template>
    </div>
    <div class="col-4">
      <q-card flat bordered class="my-card">
        <q-card-section class="row items-center">
          <div class="text-overline">Suggested Replies</div>
          <q-space />
          <div class="">
            <q-btn
              :loading="csvLoader"
              label="CSV"
              color="primary"
              icon="download_for_offline"
              @click="generateCSV"
            ></q-btn>
          </div>
        </q-card-section>

        <q-separator />

        <div class="col-6">
          <q-inner-loading :showing="loading">
            <q-spinner-gears size="50px" color="primary" />
          </q-inner-loading>
        </div>

        <q-card-section>
          <q-input
            v-model="searchTerm"
            label="Search (CTRL + f to focus)"
            ref="search"
            dense
          >
            <template v-slot:append>
              <q-icon
                v-if="searchTerm !== ''"
                name="close"
                @click="searchTerm = ''"
                class="cursor-pointer"
              />
              <q-icon name="search" />
            </template>
          </q-input>
        </q-card-section>
        <q-card-section class="q-pa-mad">
          <Quick-Message-Template
            :trackingCode="trackingCode"
            :shippingService="shippingService"
            @fillText="placeTemplate"
            @updateEditor="setChosen"
            @treeUpdate="updateSelected"
            @send="send(true, messageId)"
            @clear="clearEditor"
          />
        </q-card-section>
        <q-card-section>
          <q-scroll-area
            :thumb-style="thumbStyle"
            :bar-style="barStyle"
            style="height: 300px; max-width: 300px"
          >
            <div class="q-pa-xs q-gutter-md">
              <q-tree
                ref="tree"
                :nodes="suggestedReplies"
                node-key="node_id"
                label-key="description"
                :selected.sync="selectedNode"
                @update:selected="updateSelected"
              >
              </q-tree>
            </div>
          </q-scroll-area>
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>
<style lang="scss">
.preview-area {
  max-height: 11rem;
  overflow-y: scroll;
}
</style>
