
import Vue from "vue";
export default Vue.extend({
  name: "QuickMessageButton",
  props: {
    template: Object,
    trackingAvailable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isDisabled(): boolean {
      if (this.trackingAvailable == false && this.templateName == "Tracking") {
        return true;
      }
      return false;
    },
    templateName(): string {
      return this.template.label;
    }
  },
  methods: {
    buttonClicked() {
      console.log(this.template.templateId);
      this.$emit("buttonClicked", this.template.templateId);
    }
  }
});
