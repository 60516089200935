
import Vue from "vue";
import { mapActions } from "vuex";
import { ActionTypes } from "@/store/messages/action-types";
import { Info } from "@/services/notif-utils";
export default Vue.extend({
  name: "SendToAdminButton",
  props: ["id", "nextID"],
  data() {
    return {};
  },
  computed: {
    isManager(): boolean {
      return this.$store.getters["Auth/isManager"];
    }
  },
  methods: {
    ...mapActions({
      sendToAdminAction: "Message/" + ActionTypes.SEND_TO_ADMIN
    }),
    getButtonLabel() {
      if (this.isManager) {
        return "Assign To Self (Admin)";
      }
      return "Send To Admin";
    },
    navigateNext(): any {
      const messageId = this.nextID;
      if (messageId) {
        const nextObj = this.$store.getters["Message/messageById"](messageId);
        console.log(nextObj);
        if (nextObj.hasOrder) {
          return this.$router.push({
            name: "OrderDetail",
            params: {
              messageId: messageId,
              orderId: nextObj.orderId
            }
          });
        }
        this.$router.push({
          name: "MessageDetail",
          params: {
            messageId: messageId
          }
        });
      }
      Info("Quite Some effort! Reached End Of list!! Returning to Inbox...");
      return this.$router.push({
        name: "Unread"
      });
    },
    sendToAdmin() {
      if (this.id) {
        const messageId = this.id;
        this.sendToAdminAction(messageId)
          .then(() => {
            this.navigateNext();
            this.$q.notify({
              position: "top",
              type: "positive",
              message: "Sent to admin Successfully"
            });
          })
          .catch(error => {
            this.$q.notify({
              position: "top",
              type: "warning",
              message: "Error on send :("
            });
          });
        return;
      }
      return this.$q.notify({
        position: "top",
        type: "warning",
        message: "No message ID Collected"
      });
    }
  }
});
