export default {
  methods: {
    keyPress(event) {
      if (event.ctrlKey) {
        const keyPressed = this.keymap.find(({ key }) => {
          return key === event.key;
        });
        if (keyPressed) {
          event.preventDefault();
          keyPressed.fn();
        } else {
          return;
        }
      }
    }
  },
  created() {
    window.addEventListener("keydown", this.keyPress);
    this.keyPress(KeyboardEvent);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.keyPress);
  }
};
