
import Vue from "vue";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import { ActionTypes } from "@/store/messages/action-types";
import { KeyboardShortcuts } from "@/models";
import keyboardHelper from "@/services/keyboard-helper";
import SendToAdminButton from "@/components/SendToAdminButton.vue";
export default Vue.extend({
  name: "Message",
  components: {
    SendToAdminButton
  },
  props: {
    message: String,
    htmlMessage: String,
    nextID: String,
    fromUsername: String,
    thumbnail: String,
    title: String,
    id: String
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      sendToAdminAction: "Message/" + ActionTypes.SEND_TO_ADMIN,
      markAsReadAction: "Message/" + ActionTypes.MARK_AS_READ
    }),
    openDiscogs() {
      const itemId = this.id;
      const url = `https://www.discogs.com/sell/item/${itemId}`;
      window.open(url, "_blank");
    },
    navigateNext() {
      const messageId = this.nextID;
      if (messageId) {
        const nextObj = this.$store.getters["Message/messageById"](messageId);
        console.log(nextObj);
        if (nextObj.hasOrder) {
          return this.$router.push({
            name: "OrderDetail",
            params: {
              messageId: messageId,
              orderId: nextObj.orderId
            }
          });
        }
        this.$router.push({
          name: "MessageDetail",
          params: {
            messageId: messageId
          }
        });
      }
    },
    sendToAdmin(id: any) {
      this.navigateNext();
      this.sendToAdminAction(id)
        .then(() => {
          this.$q.notify({
            position: "top",
            type: "positive",
            message: "Sent to admin Successfully"
          });
        })
        .catch(error => {
          this.$q.notify({
            position: "top",
            type: "warning",
            message: "Error on send :("
          });
        });
    },
    doIt() {
      console.log(this.$route.params.messageId);
    }
  },
  computed: {
    ...mapGetters({
      isManager: "Auth/isManager"
    })
  },
  created() {
    const map: KeyboardShortcuts[] = [
      {
        key: "y",
        fn: this.doIt
      }
    ];
    keyboardHelper(map, false);
  }
});
