import { render, staticRenderFns } from "./MessageDetail.vue?vue&type=template&id=f8c3b476&scoped=true"
import script from "./MessageDetail.vue?vue&type=script&lang=ts"
export * from "./MessageDetail.vue?vue&type=script&lang=ts"
import style0 from "./MessageDetail.vue?vue&type=style&index=0&id=f8c3b476&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8c3b476",
  null
  
)

export default component.exports
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtnGroup from 'quasar/src/components/btn-group/QBtnGroup.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QInnerLoading,QSpinner,QBanner,QIcon,QBtnGroup,QBtn,QTooltip,QAvatar,QSeparator,QScrollArea,QList,QItem,QItemSection,QItemLabel});
