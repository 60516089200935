
import Vue from "vue";
import { PropType } from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { ActionTypes } from "@/store/messages/action-types";
import { ModuleNames } from "@/services/store-helper";
import { DataState } from "@/services/data-states";
import Api from "@/api";
import { Info, Success, Warn } from "@/services/notif-utils";
import SSA from "@/services/session-helper";
import AccountAbbrev from "@/services/AccountAbbrev";
import { SessionActionNames as SSC } from "@/models/SessionStatsConstants";
export interface ButtonType {
  bulk: boolean;
  table: boolean;
  page: boolean;
}
type messageIdsList = string[];
export default Vue.extend({
  name: "ButtonDeleteMessage",
  props: {
    messageIds: [] as PropType<messageIdsList>,
    type: Object as PropType<ButtonType>,
    currentAccountAbbrev: String as PropType<AccountAbbrev>,
  },
  data() {
    return {
      loading: false as boolean,
    };
  },
  computed: {
    ...mapGetters({
      nextMessage: "Message/nextMessage",
      bufferState: "Message/getBufferState",
    }),
    getButtonType(): ButtonType {
      return this.type;
    },
    getMessageIds(): string[] {
      return this.messageIds;
    },
  },
  methods: {
    ...mapMutations({
      setIsNextPage: "Message/SET_IS_NEXT_PAGE",
    }),
    ...mapActions({
      deleteMessagesAction:
        ModuleNames.MESSAGE_MODULE + ActionTypes.DELETE_MESSAGE,
      deleteBulkMessagesAction:
        ModuleNames.MESSAGE_MODULE + ActionTypes.DELETE_MESSAGE_BULK,
    }),
    fetchUUID(): any {
      return new Promise((resolve, reject) => {
        Api.getUUID()
          .then((uuid) => {
            resolve(uuid);
            return uuid;
          })
          .catch((error) => {
            reject(error);
            return "";
          });
        return "";
      });
    },
    async deleteMsg() {
      this.loading = true;
      if (this.bufferState == DataState.LOADING) {
        Info("Still Loading Buffer Please wait.");
        this.loading = false;
      } else {
        let uuid = "";
        const itemsToDelete = this.getMessageIds;
        let nextMessageId = undefined;
        if (this.type.page) {
          nextMessageId = this.nextMessage(itemsToDelete[0]);
        }
        if (itemsToDelete) {
          for await (const messageId of itemsToDelete) {
            console.log("deleting message");
            uuid = await this.fetchUUID();
            if (uuid) {
              try {
                await Api.Staff.deleteMessages(
                  uuid,
                  this.currentAccountAbbrev,
                  [messageId]
                );
                this.deleteMessagesAction(messageId);
                SSA.setStatAction(SSC.DELETE).catch((error) => {
                  Warn("Unable to set Action");
                });
                console.log("Successfully deleted message");
                Success("Successfullly Deleted Message");
                this.loading = false;
                if (this.type.page) {
                  this.$emit("nextPage", nextMessageId);
                }
                if (this.type.bulk) {
                  this.$emit("clearSelect");
                }
              } catch (error) {
                console.log(error);
                this.loading = false;
                Warn("Failed to Delete Message");
              }
            } else {
              Warn("Unable to generate UUID");
            }
          }
        }
      }
    },
  },
});
