
import Vue from "vue";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import { ModuleNames } from "@/services/store-helper";
import QuickMessageButton from "./QuickMessageButton.vue";
import Api from "@/api";
import { ActionTypes } from "@/store/messages/action-types";
import KeyboardShortcuts from "@/models/KeyboardShortcuts";
export default Vue.extend({
  name: "QuickMessageTemplate",
  components: {
    QuickMessageButton
  },
  props: {
    trackingCode: String,
    shippingService: String
  },
  data() {
    return {
      loading: false,
      clicked: false,
      currentTemplate: {}
      // templates: [{ key: "", label: "", messageBody: "" }],
    };
  },
  methods: {
    ...mapActions({
      loadQuickTemplatesAction:
        ModuleNames.MESSAGE_MODULE + ActionTypes.LOAD_QUICK_TEMPLATES
    }),
    keyPress(event: KeyboardEvent) {
      const keyMap: KeyboardShortcuts[] = [
        {
          key: "1",
          fn: this.welcomeBtn
        },
        {
          key: "2",
          fn: this.thanksBtn
        },
        {
          key: "3",
          fn: this.trackingBtn
        }
      ];
      if (event.ctrlKey) {
        const keyPressed = keyMap.find(({ key }) => {
          return key === event.key;
        });
        if (keyPressed) {
          event.preventDefault();
          keyPressed.fn();
        } else {
          return;
        }
      }
    },
    trackingNotPurchasedClick() {
      const templateKey = "62bcd242b4fba79355dd3402";
      this.$emit("treeUpdate", templateKey);
    },
    welcomeBtn() {
      this.update("b1");
    },
    thanksBtn() {
      this.update("b2");
    },
    trackingBtn() {
      if (this.trackingCode) {
        this.update("b3");
      } else {
        this.$q.notify({
          position: "top-left",
          message: "No tracking Code available",
          type: "warning"
        });
        return;
      }
    },
    getQuickTemplates() {
      const templatesObj = this.$store.state.General.replies;
      const templateKeys = ["b1", "b2", "b3"];
      const templateLabels = ["You're Welcome", "Thx 4 the update", "Tracking"];
      const filtered = Object.keys(templatesObj)
        .filter(key => templateKeys.includes(key))
        .reduce((obj: any, key) => {
          return {
            ...obj,
            key: key,
            messageBody: templatesObj[key]
          };
        }, {});

      this.templates = filtered;
      // templateLabels.forEach((item: any, index) => {
      //   this.templates[index].label = item;
      // });
      // // filtered.forEach((element:any) => {
      //   this.templates.push({
      //     label:
      //   })z
      // });
    },
    fillInput(item: any) {
      this.clicked = true;
      this.currentTemplate = item;
      this.$emit("fillText", item);
    },
    send() {
      this.$emit("send", this.currentTemplate);
    },
    setLabel(item: any) {
      if (!item.clicked) {
        return item.label;
      } else return "Send & Delete";
    },
    reset() {
      this.currentTemplate = {};
      this.clicked = false;
      this.$emit("clear");
    },
    update(key: any) {
      console.log("SDlsdfkbsdklf");
      console.log(key);
      this.clicked = true;
      this.$emit("updateEditor", key);
    },
    fetchTemplates() {
      this.loading = true;
      this.loadQuickTemplatesAction()
        .then(response => {
          // this.template = response.data.data;
          this.loading = false;
        })
        .catch(error => {
          console.error(error);
          this.loading = false;
        });
    }
  },
  computed: {
    ...mapGetters({
      templates: "Message/quickTemplates",
      loadedTemplates: "Message/quickTemplatesLoaded"
    }),
    trackingCodeFound(): boolean {
      if (this.trackingCode == "" || this.trackingCode === null) {
        return false;
      }
      return true;
    },
    trackingCodeNotPurchased(): boolean {
      if (this.shippingService === "AirMailSmall" && !this.trackingCodeFound) {
        return true;
      }
      return false;
    }
  },
  created() {
    window.addEventListener("keydown", this.keyPress);
    // @ts-expect-error
    this.keyPress(KeyboardEvent);
    this.getQuickTemplates();
    if (!this.loadedTemplates) {
      this.fetchTemplates();
    }
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.keyPress);
  }
});
