
import Vue from "vue";
import KeyboardShortcuts from "@/models/KeyboardShortcuts";
import { mapGetters, mapMutations } from "vuex";
import { MessageMutations } from "@/store/messages/mutation-types";
import keyboardHelper from "@/services/keyboard-helper";
import { DataState } from "@/services/data-states";
import { Info } from "@/services/notif-utils";

export default Vue.extend({
  name: "PageNavigation",
  props: {
    messageId: String,
    nextID: String,
    prevID: String
  },
  computed: {
    ...mapGetters({
      nextMessage: "Message/nextMessage",
      prevMessage: "Message/prevMessage",
      bufferLoaded: "Message/getBufferLoadedState",
      buffer: "Message/getBuffer",
      bufferStep: "Message/getBufferStep",
      bufferLoading: "Message/getBufferLoading",
      bufferState: "Message/getBufferState"
    }),
    getMessageId(): string {
      return this.messageId;
    },
    getNextBufferID(): string {
      return this.$store.getters["Buffer/getNext"](this.getMessageId);
    }
  },
  methods: {
    ...mapMutations({
      setBufferStep: "Message/" + MessageMutations.SET_BUFFER_STEP,
      setIsNextPage: "Message/" + MessageMutations.SET_IS_NEXT_PAGE
    }),
    keyPress(event: KeyboardEvent) {
      const keyMap: KeyboardShortcuts[] = [
        {
          key: "k",
          fn: this.navigateNext
        },
        {
          key: "j",
          fn: this.navigatePrev
        }
      ];
      if (event.ctrlKey) {
        const keyPressed = keyMap.find(({ key }) => {
          return key === event.key;
        });
        if (keyPressed) {
          event.preventDefault();
          keyPressed.fn();
        } else {
          return;
        }
      }
    },
    navigateNext() {
      const messageId = this.nextID;
      if (messageId) {
        const nextObj = this.$store.getters["Message/messageById"](messageId);
        console.log(nextObj);
        if (nextObj.hasOrder) {
          return this.$router.push({
            name: "OrderDetail",
            params: {
              messageId: messageId,
              orderId: nextObj.orderId
            }
          });
        }
        return this.$router.push({
          name: "MessageDetail",
          params: {
            messageId: messageId
          }
        });
      }
      Info("No Next ID, Found, heading back to Inbox.. :)");

      return this.$router.push({
        name: "Unread"
      });
    },
    navigateBuffer() {
      this.setIsNextPage(true);
      const id = this.getNextBufferID;
      const bufferObj = this.buffer[this.bufferStep];
      const hasOrder = bufferObj.hasOrder;

      if (hasOrder) {
        const orderId = bufferObj.id;
        this.$router.push({
          name: "OrderDetail",
          params: {
            messageId: id,
            orderId: orderId
          }
        });
      } else {
        this.$router.push({
          name: "MessageDetail",
          params: {
            messageId: id
          }
        });
      }
    },
    navigatePrev() {
      this.navigate(true);
    },
    navigate(flagAsPrev = false): void {
      const messageId = this.getMessageId;
      let messageObj: any;
      if (flagAsPrev) {
        messageObj = this.prevMessage(messageId);
        this.openPage(messageObj);
      } else {
        if (this.bufferLoaded) {
          this.navigateBuffer();
        } else {
          messageObj = this.nextMessage(messageId);
          this.openPage(messageObj);
        }
      }
    },
    openPage(message: any): void {
      if (message < 0) {
        this.$q.notify({
          position: "top",
          message: "Unable to move to next",
          icon: "tapas"
        });
        return;
      }
      if (message.hasOrder) {
        this.$router.push({
          name: "OrderDetail",
          params: {
            messageId: message.id,
            orderId: message.orderId
          }
        });
        return;
      } else {
        this.$router.push({
          name: "MessageDetail",
          params: {
            messageId: message.id
          }
        });
        return;
      }
    }
  },
  created() {
    window.addEventListener("keydown", this.keyPress);
    // @ts-expect-error
    this.keyPress(KeyboardEvent);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.keyPress);
  }
});
